
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EntityModal from "@/components/modals/forms/entity/EntityModal.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    EntityModal,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "2px",
        },
        {
          name: "Logo",
          key: "logo",
          sortable: true,
        },
        {
          name: "Short Name",
          key: "code",
          sortable: true,
        },
        {
          name: "Full Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Address",
          key: "address",
          sortable: true,
        },
        {
          name: "Phone",
          key: "phone",
          sortable: true,
        },
        {
          name: "Email",
          key: "email",
          sortable: true,
        },
        {
          name: "Website",
          key: "website",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          logo: "",
          name: "A.B.C Bangladesh-Turkish Technical School",
          code: "BBC",
          address: "Nazirhat Municipality ,Fatickchari",
          phone: "0312552697",
          email: "abcbdtktschool@yahoo.com",
          website: "www.doulatpurabchighschool.edu.bd",
        },
        {
          id: 2,
          logo: "",
          name: "ACA Technical Institute",
          code: "ACA",
          address: "Faterpur, Enayetnagar, Matlab Uttor, Chandpur",
          phone: "0312510677",
          email: "aca.seip@gmail.com",
          website: "www.aca.org",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.emitter.emit("add-modal-data", true);
    },
    Approve(id) {
      Swal.fire({
        title: "Are you sure you want to approve it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approved!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("entity/infos/" + `${id}`)
            .then((response) => {
              this.emitter.emit("infos-updated", true);
              Swal.fire("Deleted!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
